import React, { useContext, useEffect, useState } from 'react';
import './ZoomControls.css';
import { MapContext } from '../../context';

const ZoomControls = () => {
	const { mapRef } = useContext(MapContext);
	const [plusIsDisabled, setPlusIsDisabled] = useState(false);
	const [minusIsDisabled, setMinusIsDisabled] = useState(false);
	const [zoom, setZoom] = useState(0);

	useEffect(_ => {
		mapRef.current && mapRef.current.on('load', _ => {
			mapRef.current.on('move', _ => {
				setZoom(mapRef.current.getZoom());
			})
		})
	}, [mapRef.current]);

	useEffect(_ => {
		if (!mapRef.current) return;
		 setPlusIsDisabled(!(zoom < mapRef.current.getMaxZoom()))
		 setMinusIsDisabled(!(zoom > mapRef.current.getMinZoom()))
	}, [zoom])


	const plus = _ => {
		if (zoom + 1 < mapRef.current.getMaxZoom()) {
			mapRef.current.flyTo({
				zoom: zoom + 1
			})
			setPlusIsDisabled(false);
			setMinusIsDisabled(false);
		}
		else {
			setPlusIsDisabled(true);
		}
	}
	
	const minus = _ => {
		if (zoom - 1 > mapRef.current.getMinZoom()) {
			mapRef.current.flyTo({
				zoom: zoom - 1
			})
			setPlusIsDisabled(false);
			setMinusIsDisabled(false);
		}
		else {
			setMinusIsDisabled(true);
		}
	}

	return (
		<div className="zoom_controls">
			<button className={`zoom_controls__item zoom_controls_btn ${plusIsDisabled ? 'is-disabled' : ''}`} onClick={plus}>
				<svg className="zoom_controls_btn__icon">
					<use xlinkHref="#plus"></use>
				</svg>
			</button>
			<button className={`zoom_controls__item zoom_controls_btn ${minusIsDisabled ? 'is-disabled' : ''}`} onClick={minus}>
				<svg className="zoom_controls_btn__icon">
					<use xlinkHref="#minus"></use>
				</svg>
			</button>
		</div>
	);
};

export default ZoomControls;
