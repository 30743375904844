import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import { point as turfPoint, polygon as turfPolygon } from '@turf/helpers';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Link, Outlet, useParams } from 'react-router-dom';
import {
	ActivePointContext,
	CitiesContext,
	FiltersContext,
	MapContext,
} from '../../context';
import utils from '../../helpers/utils';
import { useFetching } from '../../hooks/useFetching';
import MapPoint from '../MapPoint/MapPoint';
import NearbyRadius from '../NearbyRadius/NearbyRadius';
import Preloader from '../Preloader';
import './Map.css';

// mapboxgl.accessToken =
// 	'pk.eyJ1IjoiYWNpZGNsIiwiYSI6ImNqeXNjNWFjbDBraXYzYm56eGtxbzc1am0ifQ.WAQgfuP6EIi6vnPcooVXLg';

const mapStyle = 'styles/morgner/ckl0duad70g9d17s0gm2sizjz';
const mapToken =
	'pk.eyJ1IjoibW9yZ25lciIsImEiOiJja2ozdTBrdGY1amZrMnhxajJia215aDZtIn0.rfu8W6sKlf4dT3vz42bzMw';
mapboxgl.accessToken = mapToken;


const apiUrl = 'https://api-oc.itome.site';

const Map = _ => {
	const mapContainerRef = useRef(null);

	const {
		mapIsLoaded,
		setMapIsLoaded,
		mapRef,
		setMapIsMoving,
		points,
		setPoints,
	} = useContext(MapContext);

	const [hasNearbyLocator, setHasNearbyLocator] = useState(false);

	const { cities, setCities } = useContext(CitiesContext);

	const [features, setFeatures] = useState([]);

	const prevFeatures = useRef([]);

	const [pointsToShow, setPointsToShow] = useState([]);

	const [loadPoints, pointsAreLoading, pointsLoadingError] = useFetching(
		async _ => {
			const req = await fetch(`${apiUrl}/api/v1/service_dev`);
			const res = await req.json();
			setPoints(res);
		}
	);

	const [loadCities, citiesAreLoading, citiesLoadingError] = useFetching(
		async _ => {
			const req = await fetch(`/cities.json`);
			const res = await req.json();
			setCities(res);
		}
	);

	const { prevCameraOptions, setPrevCameraOptions, userPositionRadius } =
		useContext(MapContext);

	const { setActivePoint, activePointIsVisible } =
		useContext(ActivePointContext);

	const { setCurrentCity } = useContext(CitiesContext);

	const { filters } = useContext(FiltersContext);

	useEffect(() => {
		mapRef.current = new mapboxgl.Map({
			container: mapContainerRef.current,
			// style: 'mapbox://styles/acidcl/cl2pitqzu00fs14pkq2g7kswv?optimize=true',
			style: `mapbox://${mapStyle}?optimize=true`,
			center: [131.885483, 43.115761],
			zoom: 11,
			minZoom: 5,
			pitch: 45,
			testMode: true,
		});

		setPrevCameraOptions({
			center: [131.885483, 43.115761],
		});

		const map = mapRef.current;

		map.on('load', _ => {
			setMapIsLoaded(true);

			map.getStyle()
				.layers.filter(layer => layer.id.includes('tunnel'))
				.forEach(layer => map.removeLayer(layer.id));

			map.addSource('points', {
				type: 'geojson',
				data: points,
				cluster: true,
				tolerance: 10,
				clusterMaxZoom: 20,
				clusterRadius: 50,
				buffer: 0,
			});
			map.addLayer({
				id: 'clusters',
				type: 'symbol',
				source: 'points',
				filter: ['has', 'point_count'],
			});

			map.addSource('cities', {
				type: 'geojson',
				data: cities,
				tolerance: 1,
				buffer: 10,
			});

			map.addLayer({
				id: 'cities',
				type: 'fill',
				source: 'cities',
				layout: {},
				paint: {
					'fill-color': '#a0bcff',
					'fill-opacity': 0,
				},
			});
			map.addLayer({
				id: 'cities-outline',
				type: 'line',
				source: 'cities',
				layout: {},
				paint: {
					'line-color': '#a0bcff',
					'line-width': 1.5,
					'line-opacity': 0,
				},
			});

			map.on('render', _ => {
				if (map.isMoving() || !map.isSourceLoaded('points')) return;
				setFeatures(map.querySourceFeatures('points'));
			});

			map.on('render', _ => {
				if (map.isMoving() || !map.isSourceLoaded('cities')) return;
				detectCity();
			});

			map.on('movestart', _ => {
				setMapIsMoving(true);
			});
			map.on('click', _ => {
				setMapIsMoving(true);
				setTimeout(_ => {
					setMapIsMoving(false);
				});
			});
			map.on('moveend', _ => {
				setMapIsMoving(false);
			});
		});

		return _ => {
			map.remove();
		};
	}, []);

	useEffect(
		_ => {
			mapIsLoaded && loadPoints() && loadCities();
		},
		[mapIsLoaded]
	);

	const params = useParams();

	useEffect(
		_ => {
			const map = mapRef.current;
			if (
				!mapIsLoaded ||
				!map.getSource('points') ||
				!map.isSourceLoaded('points')
			)
				return;
			map.getSource('points').setData(points);

			if (params.id) {
				const point = points.features.find(
					point => +point.properties.id === +params.id
				);
				point && setActivePoint(point);
				map.flyTo({
					center: point.geometry.coordinates,
					zoom: 15,
				});
			}
		},
		[points]
	);

	useEffect(
		_ => {
			const map = mapRef.current;
			if (
				!mapIsLoaded ||
				!map.getSource('points') ||
				!map.isSourceLoaded('points')
			)
				return;
			if (!params.id) {
				setActivePoint(null);
				return;
			}

			const point = points.features.find(
				point => +point.properties.id === +params.id
			);
			point && setActivePoint(point);
		},
		[params]
	);

	useEffect(
		_ => {
			const map = mapRef.current;
			if (
				!mapIsLoaded ||
				!map.getSource('cities') ||
				!map.isSourceLoaded('cities')
			)
				return;
			map.getSource('cities').setData(cities);
		},
		[cities]
	);

	useEffect(
		_ => {
			!activePointIsVisible &&
				prevCameraOptions &&
				mapRef.current.flyTo(prevCameraOptions);
		},
		[activePointIsVisible]
	);

	useEffect(
		_ => {
			const map = mapRef.current;
			if (
				!mapIsLoaded ||
				!map.getSource('points') ||
				!map.isSourceLoaded('points')
			)
				return;

			setHasNearbyLocator(filters.has('geolocation'));

			if (!filters.size) {
				map.getSource('points').setData(points);
				return;
			}

			const filteredPoints = {
				type: 'FeatureCollection',
				features: points.features.filter(point => {
					const categoriesIds = point.properties.categories.map(
						c => c.id
					);
					const categoryFit =
						filters.size === 1 && filters.has('geolocation')
							? true
							: categoriesIds.some(id =>
									filters.has(id.toString())
							  );

					if (filters.has('geolocation')) {
						const pt = turfPoint(point.geometry.coordinates);
						const poly = turfPolygon(
							userPositionRadius.data.features[0].geometry
								.coordinates
						);

						return categoryFit && booleanPointInPolygon(pt, poly);
					}
					return categoryFit;
				}),
			};
			map.getSource('points').setData(filteredPoints);
		},
		[filters, userPositionRadius]
	);

	const pts = useMemo(
		_ => {
			if (!features) return [];

			const sourcePoints = features;
			const clusterIds = new Set();
			const markerIds = new Set();

			const uniquePoints = sourcePoints.filter(point => {
				if (point.properties.cluster) {
					if (!clusterIds.has(point.properties.cluster_id)) {
						clusterIds.add(point.properties.cluster_id);
						return true;
					}
				} else {
					if (!markerIds.has(point.properties.id)) {
						markerIds.add(point.properties.id);
						return true;
					}
				}
			});
			return uniquePoints;
		},
		[features]
	);

	useEffect(
		_ => {
			if (
				JSON.stringify(prevFeatures.current) !==
				JSON.stringify(features)
			) {
				setPointsToShow(pts);
				prevFeatures.current = features;
			}
		},
		[features]
	);

	// const updateFeatures = _ => {
	// 	const map = mapRef.current;
	// 	const sourcePoints = map.querySourceFeatures('points');
	// 	const clusterIds = new Set();
	// 	const markerIds = new Set();

	// 	const uniquePoints = sourcePoints.filter(point => {
	// 		if (point.properties.cluster) {
	// 			if (!clusterIds.has(point.properties.cluster_id)) {
	// 				clusterIds.add(point.properties.cluster_id);
	// 				return true;
	// 			}
	// 		} else {
	// 			if (!markerIds.has(point.properties.id)) {
	// 				markerIds.add(point.properties.id);
	// 				return true;
	// 			}
	// 		}
	// 	});

	// 	setPointsToShow(uniquePoints);
	// };

	const detectCity = _ => {
		const map = mapRef.current;
		const citiesInView = map
			.queryRenderedFeatures(null, { layers: ['cities'] })
			.reduce((keys, feature) => {
				const key = feature.properties.city;
				if (!~keys.indexOf(key)) {
					keys.push(key);
				}
				return keys;
			}, []);

		if (citiesInView.length > 1) {
			map.setPaintProperty('cities', 'fill-opacity', 0);
			map.setPaintProperty('cities-outline', 'line-width', 0);
			setCurrentCity(null);
		} else {
			setCurrentCity(citiesInView[0]);
			let fillOpacity = utils.map(map.getZoom(), 14, 9, 0, 0.3);
			if (fillOpacity > 0.3) fillOpacity = 0.3;
			if (fillOpacity < 0) fillOpacity = 0;
			map.setPaintProperty('cities', 'fill-opacity', fillOpacity);

			let lineWidth = utils.map(map.getZoom(), 15, 9, 1.5, 2.5);
			if (lineWidth < 1.5) lineWidth = 1.5;
			if (lineWidth > 2.5) lineWidth = 2.5;

			map.setPaintProperty('cities-outline', 'line-opacity', 1);
			map.setPaintProperty('cities-outline', 'line-width', lineWidth);
		}
	};

	return (
		<div className="map">
			<div ref={mapContainerRef} className="map-container"></div>
			{mapIsLoaded && (citiesAreLoading || pointsAreLoading) && (
				<div className="data_preloader">
					<Preloader />
				</div>
			)}
			{pointsToShow.map((point, i) => {
				const props = point.properties;
				const key = props.cluster_id
					? `cluster-${props.cluster_id}`
					: `marker-${props.id}`;
				return props.cluster ? (
					<MapPoint data={point} key={key} />
				) : (
					<Link to={`/point/${props.id}`} key={key}>
						<MapPoint data={point} />
					</Link>
				);
			})}
			{hasNearbyLocator && <NearbyRadius />}
			<Outlet />
		</div>
	);
};
// Map.whyDidYouRender = true;
export default Map;
