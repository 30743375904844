import React, { useContext, useLayoutEffect, useMemo, useState } from 'react';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import { MapContext, CitiesContext, PortalsRefsContext } from '../../context';

const CitiesListContent = ({ cities }) => {
	const regions = useMemo(_ => {
		return [
			...new Set(
				cities.features.map(feature => feature.properties.region)
			),
		].map(region => ({
			name: region,
			cities: cities.features.filter(
				city => city.properties.region === region
			),
		}));
	}, []);

	const { mapRef } = useContext(MapContext);
	const { currentCity } = useContext(CitiesContext);
	const { citiesRootRef } = useContext(PortalsRefsContext);

	const [maxHeight, setMaxHeight] = useState(0);

	const isMobile = window.matchMedia('(max-width: 768px)').matches;

	useLayoutEffect(
		_ => {
			if (isMobile && citiesRootRef.current) {
				const style = getComputedStyle(citiesRootRef.current);
				setMaxHeight(
					citiesRootRef.current.clientHeight -
						parseFloat(style.paddingTop) -
						parseFloat(style.paddingBottom)
				);
			} else {
				setMaxHeight(window.innerHeight - 200);
			}
		},
		[citiesRootRef.current]
	);

	return (
		regions && (
			// <div className="cities_list__wrapper">
			<SimpleBar style={{ maxHeight: maxHeight }} autoHide={false}>
				
				<div className="cities_list__container">
				{isMobile && (
					<div className="modal_controls cities_list__controls">
						<button class="modal_back_btn" onClick={_ =>
											mapRef.current.flyTo({	
												duration: 2,
											})
										}>
							<svg class="modal_back_btn__icon">
								<use xlinkHref="#chevron"></use>
							</svg>
							<span className="modal_back_btn__text">Назад</span>
						</button>
						<button class="modal_close_btn" onClick={_ =>
											mapRef.current.flyTo({	
												duration: 2,
											})
										}>
							<svg class="modal_close_btn__icon">
								<use xlinkHref="#cross"></use>
							</svg>
						</button>
					</div>
				)}
					{regions.map((region, i) => (
						<div
							className="cities_list__region cities_list_region"
							key={i}
						>
							<h3 className="cities_list_region__title">
								{region.name}
							</h3>
							<div className="cities_list_region__cities">
								{region.cities.map((city, i) => (
									<button
										className={`cities_list_region__city cities_list_city ${
											currentCity === city.properties.city
												? 'is-active'
												: ''
										}`}
										key={i}
										onClick={_ =>
											mapRef.current.flyTo({
												center: city.properties.center,
												zoom: 13,
												duration: 2,
												curve: 1,
											})
										}
									>
										<svg className="cities_list_city__icon">
											<use xlinkHref="#compass"></use>
										</svg>
										<span className="cities_list_city__name">
											{city.properties.city}
										</span>
									</button>
								))}
							</div>
						</div>
					))}
				</div>
			</SimpleBar>
			// </div>
		)
	);
};

export default CitiesListContent;
