import React, { useContext, useLayoutEffect, useState } from 'react';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import SearchResultGroup from './SearchResultGroup';
import SearchResultItem from './SearchResultItem';
import { SearchContext, PortalsRefsContext } from '../../context';

const SearchResults = ({ results, isPrevResults, query, isVisible }) => {
	const { setNeedToCheckLocalStorage, setIsClicked } = useContext(SearchContext);

	const clearHistory = _ => {
		localStorage.removeItem('prevSearchResults');
		setNeedToCheckLocalStorage(true);
	};

	const { searchResultsRootRef } = useContext(PortalsRefsContext);

	const [maxHeight, setMaxHeight] = useState(0);

	const isMobile = window.matchMedia('(max-width: 768px)').matches;

	useLayoutEffect(
		_ => {
			if (isMobile && searchResultsRootRef.current) {
				setMaxHeight(searchResultsRootRef.current.clientHeight);
			} else {
				setMaxHeight(window.innerHeight / 2);
			}
		},
		[searchResultsRootRef.current, isVisible]
	);

	return (
		<>
			<SimpleBar style={{ maxHeight: maxHeight}}>
				<div className="search_list__container">
					{(isMobile && query.length === 0) ||
						(results.length < 1 && (
							<p className="search_result search_result--info">
								По вашему запросу ничего не нашлось
							</p>
						))}
					{isPrevResults && (
						<p className="search_result search_result--info">
							Вы искали ранее:
							<button
								className="search__clear_history"
								onClick={clearHistory}
							>
								Очистить историю
							</button>
						</p>
					)}
					{results.map((result, i) => {
						if (result.length > 1) {
							return <SearchResultGroup key={i} group={result} />;
						} else {
							return (
								<SearchResultItem
									key={i}
									item={result[0].item}
								/>
							);
						}
					})}
				</div>
			</SimpleBar>
			{isMobile && (
				<div className="search_list__close_wrapper">
					<button
						class="border_btn search_list__close"
						onClick={_ => setIsClicked(false)}
					>
						Закрыть
					</button>
				</div>
			)}
		</>
	);
};

export default SearchResults;
