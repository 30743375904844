import React, { useEffect, useState } from 'react'
import './Textarea.css';


const Textarea = ({ classes = [], label, onChange, ...props }) => {
	const [value, setValue] = useState('');
	const [isFilled, setIsFilled] = useState('');
	
	const className = ['input', `${isFilled ? 'is-filled' : ''}`, ...classes].join(' ');

	const handleChange = evt => {
		setValue(evt.target.value);
	}
	useEffect(_ => {
		setIsFilled(value.length > 1);
	}, [value]);


	return (
		<div className={className}>
			<textarea {...props} className="input__field" onChange={handleChange} value={value}/>
			<label class="input__label">{label}</label>
		</div>
	);
};

export default Textarea