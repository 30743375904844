import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';
import {
	MapContext,
	HeaderActiveModalContext,
	FiltersContext,
	PortalsRefsContext,
} from '../../context';
import HeaderButton from '../Header/HeaderButton';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import { useFetching } from '../../hooks/useFetching';
import './FiltersList.css';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import FiltersListContent from './FiltersListContent';
import FiltersListItems from './FiltersListItems';
import { createPortal } from 'react-dom';

const FiltersList = () => {
	const apiUrl = 'https://api-oc.itome.site';

	const [isVisible, setIsVisible] = useState(false);

	const [filtersData, setFiltersData] = useState({});

	const { filters, setFilters } = useContext(FiltersContext);

	const { activeModal, setActiveModal } = useContext(
		HeaderActiveModalContext
	);

	const [activeCategory, setActiveCategory] = useState(null);

	const [selectedFilters, setSelectedFilters] = useState(new Set());

	const [counters, setCounters] = useState([]);

	const { filtersRootRef } = useContext(PortalsRefsContext);

	const [root, setRoot] = useState(null);

	const { mapRef } = useContext(MapContext);

	useEffect(
		_ => {
			filtersRootRef &&
				filtersRootRef.current &&
				setRoot(filtersRootRef.current);
		},
		[filtersRootRef.current]
	);

	const isMobile = window.matchMedia('(max-width: 768px)').matches;

	const [isClicked, setIsClicked] = useState(false);

	useEffect(
		_ => {
			setIsVisible(activeModal === 'filtersList');
		},
		[activeModal]
	);

	useEffect(
		_ => {
			isVisible && setActiveModal('filtersList');
			setTimeout(_ => {
				!isVisible && setActiveCategory(null);
			}, 170);
			setIsClicked(isVisible);
		},

		[isVisible]
	);

	const handleOnCLick = _ => {
		setIsVisible(!isVisible);
		setIsClicked(true);
	};

	const [loadFilters, filtersAreLoading, filtersLoadingError] = useFetching(
		async _ => {
			const req = await fetch(`${apiUrl}/api/v1/service_category`);
			const res = await req.json();
			setFiltersData(res);
		}
	);

	const apply = _ => {
		const allFiltersData = Object.values(filtersData).flatMap(category =>
			Object.values(category)
		);

		const specialFilters = [];
		filters.has('geolocation') &&
			specialFilters.push(['geolocation', filters.get('geolocation')]);

		selectedFilters.has('-1') &&
			specialFilters.push([
				'-1',
				{
					id: -1,
					name: 'События',
				},
			]);
		const newFilters = new Map([
			...specialFilters,
			...[...selectedFilters]
				.filter(filterId => +filterId !== -1)
				.map(filterId => [
					filterId,
					allFiltersData.find(data => +data.id === +filterId),
				]),
		]);

		setFilters(newFilters);
		setIsVisible(false);
	};

	const clear = _ => {
		setFilters(new Map());
		setSelectedFilters(new Set());
		setIsVisible(false);
	};

	useEffect(_ => {
		loadFilters();
	}, []);

	useEffect(
		_ => {
			setCounters(
				Object.keys(filtersData).map(key => ({
					id: key,
					count: filtersData[key].filter(item =>
						selectedFilters.has(item.id)
					).length,
				}))
			);
		},
		[selectedFilters]
	);

	useEffect(
		_ => {
			const selectedFiltersCopy = new Set(selectedFilters);
			[...selectedFiltersCopy].forEach(filterId => {
				if (!filters.has(filterId))
					selectedFiltersCopy.delete(filterId);
			});
			setSelectedFilters(selectedFiltersCopy);
		},
		[filters]
	);

	useLayoutEffect(
		_ => {
			root && root.classList.toggle('is-visible', isClicked);
		},
		[isClicked]
	);

	const btnText = window.matchMedia(
		'(max-width: 1000px) and (min-width: 769px)'
	).matches
		? 'Фильтры'
		: 'Фильтровать точки';

	const getContent = _ => {
		return (
			<div className="filters_list" onClick={e => e.stopPropagation()}>
				{isMobile && (
					<div className="modal_controls cities_list__controls">
						<button
							class="modal_back_btn"
							onClick={_ => {
								if (activeCategory) {
									setActiveCategory(null);
								} else {
									mapRef.current.flyTo({
										duration: 2,
									});
								}
							}}
						>
							<svg class="modal_back_btn__icon">
								<use xlinkHref="#chevron"></use>
							</svg>
							<span className="modal_back_btn__text">Назад</span>
						</button>
						<button
							class="modal_close_btn"
							onClick={_ =>
								mapRef.current.flyTo({
									duration: 2,
								})
							}
						>
							<svg class="modal_close_btn__icon">
								<use xlinkHref="#cross"></use>
							</svg>
						</button>
					</div>
				)}
				<SimpleBar
					style={{
						maxHeight: window.innerHeight * (isMobile ? 0.8 : 0.7),
					}}
				>
					<SwitchTransition>
						<CSSTransition
							key={activeCategory ? activeCategory : -1}
							timeout={170}
							classNames={'filters_list__list'}
						>
							<>
								{!activeCategory && (
									<FiltersListContent
										setActiveCategory={setActiveCategory}
										selectedFilters={selectedFilters}
										setSelectedFilters={setSelectedFilters}
										counters={counters}
									/>
								)}
								{activeCategory &&
									filtersData[activeCategory] && (
										<FiltersListItems
											selectedFilters={selectedFilters}
											setSelectedFilters={
												setSelectedFilters
											}
											activeCategoryId={activeCategory}
											activeCategory={
												filtersData[activeCategory]
											}
											setActiveCategory={
												setActiveCategory
											}
										/>
									)}
							</>
						</CSSTransition>
					</SwitchTransition>
					<CSSTransition
						in={selectedFilters.size > 0}
						unmountOnExit
						classNames="filters_list__btns"
						timeout={250}
					>
						<div className="filters_list__btns">
							{filters.size > 0 && (
								<button
									className="filters_list__btn filters_list__clear"
									onClick={clear}
								>
									Сбросить фильтры
								</button>
							)}
							<button
								className="filters_list__btn filters_list__apply"
								onClick={apply}
							>
								Применить
							</button>
						</div>
					</CSSTransition>
				</SimpleBar>
			</div>
		);
	};

	return (
		<div className="header__modal_wrapper">
			<HeaderButton
				onClick={handleOnCLick}
				className={isVisible ? 'is-clicked' : ''}
			>
				<svg className="header_btn__icon">
					<use xlinkHref="#marker"></use>
				</svg>
				<span className="header_btn__text">{btnText}</span>
				<svg className="header_btn__state_icon">
					<use xlinkHref="#chevron"></use>
				</svg>
			</HeaderButton>

			<CSSTransition
				in={isVisible}
				unmountOnExit
				classNames="filters_list__container"
				timeout={100}
			>
				<>
					{isMobile && root && createPortal(getContent(), root)}
					{!isMobile && getContent()}
				</>
			</CSSTransition>
		</div>
	);
};

export default FiltersList;
