import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import anime from 'animejs/lib/anime.es.js';
import React, { useContext, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { FiltersContext, MapContext } from '../../context';
import { createGeoJSONCircle } from '../../helpers/geojson';
import UserMarker from '../MapPoint/UserMarker';

const NearbyRadius = () => {
	const { filters } = useContext(FiltersContext);
	const { mapRef, userPosition, userPositionRadius, setUserPosition } =
		useContext(MapContext);

	const marker = useRef(null);
	const isFirstRender = useRef(true);

	useEffect(
		_ => {
			if (filters.get('geolocation') && userPosition) {
				const center = userPosition;
				const dataObjects = [];
				const initialCircleGeoJSON = createGeoJSONCircle(
					{ longitude: center[0], latitude: center[1] },
					1
				);
				[1, 2, 3].forEach(i => {
					const id = `polygon-${i}`;
					if (!mapRef.current.getSource(id)) {
						mapRef.current.addSource(id, initialCircleGeoJSON);
					}
					if (!mapRef.current.getLayer(id)) {
						mapRef.current.addLayer({
							id: id,
							type: 'fill',
							source: id,
							layout: {},
							paint: {
								'fill-color': 'rgb(59, 219, 185)',
								'fill-opacity': 0,
							},
						});
					}
					dataObjects.push({
						distance: 0,
						opacity: 0,
					});
					mapRef.current.once('moveend', _ => {
						anime({
							targets: dataObjects[i - 1],
							distance: 1,
							opacity: [0.4, 0.1],
							easing: 'easeOutQuad',
							round: 1000,
							delay: 700 + 200 * (i - 1),
							duration: !isFirstRender.current ? 0 : 1000,
							update: function () {
								const circleGeoJSON = createGeoJSONCircle(
									{
										longitude: center[0],
										latitude: center[1],
									},
									dataObjects[i - 1].distance
								);
								mapRef.current
									.getSource(id)
									.setData(circleGeoJSON.data);
								mapRef.current.setPaintProperty(
									id,
									'fill-opacity',
									dataObjects[i - 1].opacity
								);
							},
							complete: _ => {
								if (i === 3) {
									isFirstRender.current = false;
								}
							},
						});
					});

					// );
				});

				mapRef.current.flyTo({
					zoom: 14,
					center: center,
					duratrion: 1400,
				});

				if (!marker.current) {
					const el = document.createElement('div');
					el.style.zIndex = 2;

					marker.current = new mapboxgl.Marker({
						element: el,
						offset: [0, -46],
						draggable: true,
					}).setLngLat(center);
				}
				marker.current.on('dragstart', _ => {
					marker.current.getElement().classList.add('on-drag');
				});
				marker.current.on('dragend', _ => {
					const coordinates = marker.current.getLngLat();
					setUserPosition([coordinates['lng'], coordinates['lat']]);
					isFirstRender.current = false;
					marker.current.getElement().classList.remove('on-drag');
				});
			}
		},
		[filters, userPosition]
	);

	useEffect(_ => {
		return _ => {
			[1, 2, 3].forEach(i => {
				const id = `polygon-${i}`;
				if (mapRef.current.getLayer(id)) {
					mapRef.current.removeLayer(id);
				}
				if (mapRef.current.getSource(id)) {
					mapRef.current.removeSource(id);
				}
			});
		};
	}, []);

	useEffect(_ => {
		if (marker.current) {
			marker.current.addTo(mapRef.current);

			return _ => marker.current.remove();
		}
	}, []);

	if (marker.current)
		return createPortal(<UserMarker />, marker.current.getElement());
};

export default NearbyRadius;
